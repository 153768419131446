import { template as template_ca1f6ae53b784056835caa57e68ae02e } from "@ember/template-compiler";
const FKLabel = template_ca1f6ae53b784056835caa57e68ae02e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
