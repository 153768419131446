import { template as template_4a8b710b003445248332ceb522d64275 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4a8b710b003445248332ceb522d64275(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
